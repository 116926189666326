<template>
  <div class="container h-100 content">
    <div class="d-flex justify-content-center h-100">
      <div class="login block">
        <form @submit.prevent="login">
          <img
            alt="Data Energy"
            v-bind:src="getLogoBase64"
            style="width:80%; margin-bottom: 20px; max-width: 350px;"
          />

          <div v-if="state === 'mfa_code'">
            <LoginMFACode
              @reset="resetLogin"
              @success="processSuccessfulLogin"
              :auth-token="authToken"
            ></LoginMFACode>
          </div>

          <div v-else>
            <div class="sub-detail">
              <MessageBlock :messages="messages"></MessageBlock>

              <p>We manage a variety of prepayment solutions.</p>

              <p>
                If you have a Guru or Secure prepayment meter, please use the
                following links. For everything else, please sign in below.
              </p>
            </div>

            <b-card no-body>
              <b-tabs pills class="login-selection" fill>
                <b-tab title="Guru Hub">
                  <b-card-text>
                    <div class="integration-logo">
                      <img
                        alt="Guru"
                        src="../../assets/integrations/logo-guru.png"
                      />
                    </div>

                    <div class="integration-logo">
                      <img
                        alt="Guru Meter"
                        src="../../assets/integrations/device-guru.png"
                      />
                    </div>

                    <p>
                      If you have a Guru Meter (shown above), please
                      <a
                        href="https://dataenergygurupayments.paypoint.com/"
                        target="_blank"
                        >click here</a
                      >
                    </p>
                  </b-card-text>
                </b-tab>
                <b-tab title="Secure">
                  <b-card-text>
                    <div class="integration-logo">
                      <img
                        alt="Secure"
                        src="../../assets/integrations/logo-secure.png"
                      />
                    </div>

                    <div class="integration-logo">
                      <img
                        alt="Secure Meter"
                        src="../../assets/integrations/device-secure.jpeg"
                      />
                    </div>
                    <p>
                      If you have a Secure Meter (shown above), please
                      <a
                        href="https://iknowattpayments.paypoint.com/"
                        target="_blank"
                        >click here</a
                      >
                    </p>
                  </b-card-text>
                </b-tab>
                <b-tab title="Everything Else" active>
                  <b-card-text>
                    <h2>{{ env }}Sign In</h2>

                    <b-alert variant="info" show v-if="activationRequired">
                      <strong>Activation Required</strong>
                      <div>
                        Your account requires e-mail activation. We have resent
                        you a link to your e-mail account.
                      </div>
                    </b-alert>

                    <div class="element">
                      <input
                        required
                        v-model="email"
                        type="email"
                        placeholder="E-mail"
                      />
                    </div>

                    <div class="element">
                      <input
                        required
                        v-model="password"
                        type="password"
                        placeholder="Password"
                      />
                    </div>

                    <div>
                      <span @click="loadPassword" class="password-link"
                        >Forgotten Password?</span
                      >
                    </div>

                    <b-button type="submit" variant="success">Login</b-button>

                    <hr />

                    <h3>Not got an account?</h3>

                    <b-button @click="loadRegister" variant="outline-info"
                      >Register now</b-button
                    >
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { recaptcha } from "../../components/mixins/recaptcha";
import { logoData } from "@/components/mixins/logo";
import MessageBlock from "@/views/Components/Global/MessageBlock";
import LoginMFACode from "@/views/Auth/LoginMFACode";

export default {
  name: "Login",
  components: { LoginMFACode, MessageBlock },
  props: ["payRef"],
  mixins: [recaptcha, logoData],
  data() {
    return {
      authToken: "",
      state: "login",
      email: "",
      password: "",
      activationRequired: false,
      routeRedirect: null
    };
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    messages() {
      return this.$store.state.auth.messages;
    }
  },
  created() {
    this.setPayPointReference();
    this.fetchData();
  },
  mounted() {
    this.routeRedirect = this.$route.query.redirect;
  },
  methods: {
    resetLogin() {
      this.authCode = "";
      this.email = "";
      this.password = "";
      this.state = "login";
    },
    loadRegister() {
      this.$router.push("/register");
    },
    loadPassword() {
      this.$router.push("/forgotten-password");
    },
    setPayPointReference: function() {
      if (this.payRef) {
        this.reference = this.payRef;
      }
    },
    async login() {
      this.activationRequired = false;
      const title = "Logging you in";
      const subtitle = "Please wait a moment";
      await this.$store.dispatch("loaderInitiate", { title, subtitle });

      const token = await this.recaptchaToken();

      const email = this.email;
      const password = this.password;
      this.$store
        .dispatch("login", { email, password, token })
        .then(response => {
          if (response.state === "require_mfa_code") {
            this.state = "mfa_code";
            this.authToken = response.auth_code;
            this.$store.dispatch("loaderCancel");
            return;
          }

          this.processSuccessfulLogin();
        })
        .catch(err => {
          this.$store.dispatch("loaderCancel");

          if (err === "Account requires activation") {
            this.activationRequired = true;
            return;
          }

          this.$swal({
            type: "error",
            title: "Oops...",
            text: err
          });
        })
        .finally(() => {});
    },
    processSuccessfulLogin() {
      let path = "/";
      if (this.routeRedirect) {
        path = this.routeRedirect;
      }
      this.$router.push(path);
    },
    fetchData() {
      this.$store
        .dispatch("showPublicMessages")
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          const title = "Error accessing Messages";
          const subtitle = err;
          this.$store.dispatch("pushError", { title, subtitle });
        })
        .finally(() => {
          this.$store.dispatch("loaderCancel");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.password-link {
  cursor: pointer;
  font-size: 0.8rem;
}

h2 {
  text-align: center !important;
  font-size: 1.4rem !important;
}

h3 {
  font-size: 1.1rem;
}

.login {
  width: 450px;
}

.sub-detail {
  margin-top: 20px;
}

p {
  font-size: 0.9rem;
}

.integration-logo {
  padding: 15px 0px;
  img {
    width: 80%;
    max-width: 200px;
  }
}

.card {
  border: 0px !important;
}

form {
  width: 400px;
}
</style>
