<template>
  <div v-if="messages.length > 0" class="message-block">
    <b-alert
      v-for="(message, i) in messages"
      :key="i"
      variant="warning"
      show
      class="warn-message"
    >
      <strong>{{ message.title }}</strong>
      <div class="date">
        {{ message.available_at | moment("MMMM Do YYYY HH:mm") }}
      </div>
      <div v-html="message.message"></div>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "MessageBlock",
  props: {
    messages: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.message-block {
  margin-top: 20px;
}

.warn-message {
  div {
    font-size: 0.9rem;
    padding: 5px 0px;
  }
  div.date {
    font-size: 0.8rem;
    padding-top: 0px;
  }
}
</style>
